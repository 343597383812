import React, { type ReactNode } from 'react'
import styled from 'styled-components'
import {
  alignSelf as alignSelfStyle,
  fontWeight as fontWeightStyle,
  textAlign as textAlignStyle,
  textStyle as textHeaderStyle,
  space as spaceStyle,
  type FontSizeProps,
  type SpaceProps,
  type TextAlignProps,
  type TextStyleProps,
  type TextColorProps,
  type LineHeightProps
} from 'styled-system'
import { Heading } from 'pcln-design-system'

// eslint-disable-next-line @typescript-eslint/unbound-method
const StyledH1 = styled(Heading.h1)`
  ${textAlignStyle};
  ${spaceStyle};
  ${textHeaderStyle}
`

// eslint-disable-next-line @typescript-eslint/unbound-method
const StyledH2 = styled(Heading.h2)`
  ${alignSelfStyle};
  ${textAlignStyle};
  ${fontWeightStyle};
  ${spaceStyle};
`

const HEADINGS = {
  h1: StyledH1,
  h2: StyledH2
} as const

type StyledHeadingType = FontSizeProps &
  SpaceProps &
  TextAlignProps &
  TextStyleProps &
  LineHeightProps &
  TextColorProps & {
    text: ReactNode
    tag: 'h1' | 'h2'
  }

export default function StyledHeading({
  color = 'text.base',
  text,
  textAlign,
  tag,
  textStyle,
  ...props
}: StyledHeadingType) {
  const Component = HEADINGS[tag]

  return (
    <Component
      fontWeight={tag === 'h2' ? '400' : ['600', null, '700']}
      textAlign={textAlign || 'left'}
      color={color}
      textStyle={textStyle}
      m={0}
      {...props}
    >
      {text}
    </Component>
  )
}
