import type {
  IMerchModule,
  ICollectionType,
  IVariationCollectionType,
  IMediaCard,
  ISpotlightCollection,
  ISilhouetteMarketingModuleType,
  VariationItem,
  IHeroCollection,
  IHomepageOrder
} from '@pcln/contentful-components'

export function hasContentTypeId(
  content: unknown
): content is { contentTypeId: string } {
  return (
    typeof content === 'object' &&
    content !== null &&
    'contentTypeId' in content &&
    typeof content.contentTypeId === 'string' &&
    content.contentTypeId.trim() !== ''
  )
}

export function isIMerchModule(content: unknown): content is IMerchModule {
  return hasContentTypeId(content) && content.contentTypeId === 'merchModule'
}

export function isIMediaCard(content: unknown): content is IMediaCard {
  return hasContentTypeId(content) && content.contentTypeId === 'mediaCard'
}

export function isISpotlightCollection(
  content: unknown
): content is ISpotlightCollection {
  return (
    hasContentTypeId(content) && content.contentTypeId === 'spotlightCollection'
  )
}

export function isISilhouetteMarketingModuleType(
  content: unknown
): content is ISilhouetteMarketingModuleType {
  return (
    hasContentTypeId(content) &&
    content.contentTypeId === 'silhouetteMarketingModule'
  )
}

export function isICollectionType(
  content: unknown
): content is ICollectionType {
  return (
    hasContentTypeId(content) &&
    content.contentTypeId === 'collection' &&
    'items' in content &&
    Array.isArray(content.items)
  )
}

export function isCollectionOfIMerchModule(
  content: unknown
): content is ICollectionType<IMerchModule> {
  return (
    isICollectionType(content) &&
    content.items.every(item => item.contentTypeId === 'merchModule')
  )
}

export function isCollectionOfIMediaCard(
  content: unknown
): content is ICollectionType<IMediaCard> {
  return (
    isICollectionType(content) &&
    content.items.every(item => item.contentTypeId === 'mediaCard')
  )
}

export function isIVariationCollectionType(
  content: unknown
): content is IVariationCollectionType {
  return (
    hasContentTypeId(content) &&
    content.contentTypeId === 'variationCollection' &&
    'experimentName' in content &&
    typeof content.experimentName === 'string' &&
    content.experimentName.trim() !== '' &&
    'defaultContent' in content &&
    content.defaultContent !== null &&
    'variant1Name' in content &&
    typeof content.variant1Name === 'string' &&
    content.variant1Name.trim() !== '' &&
    'variant1Content' in content &&
    content.variant1Content !== null
  )
}

export function isExperimentOfSpecifiedType<T extends VariationItem>(
  content: IVariationCollectionType | undefined,
  predicate: (content: unknown) => content is T
): content is IVariationCollectionType<T> {
  if (
    content &&
    predicate(content.defaultContent) &&
    predicate(content.variant1Content) &&
    (!content.variant2Content ||
      (content.variant2Content && predicate(content.variant2Content))) &&
    (!content.variant3Content ||
      (content.variant3Content && predicate(content.variant3Content))) &&
    (!content.variant4Content ||
      (content.variant4Content && predicate(content.variant4Content))) &&
    (!content.variant5Content ||
      (content.variant5Content && predicate(content.variant5Content)))
  ) {
    return true
  }
  return false
}

export function isIHeroCollection(
  content: unknown
): content is IHeroCollection {
  return hasContentTypeId(content) && content.contentTypeId === 'heroCollection'
}

export function isHomepageOrder(content: unknown): content is IHomepageOrder {
  return hasContentTypeId(content) && content.contentTypeId === 'homepageOrder'
}
