import styled from 'styled-components'

export const ExpandableWrapper = styled.div<{ $isOpen: boolean }>`
  margin: 0 0 12px 0;
  break-inside: avoid;
  cursor: pointer;
  padding: 14px 16px;
  border-radius: 12px;
  box-shadow: 0 1px 3px 0 rgba(23, 29, 46, 0.12);
  min-width: 300px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`

export const ExpandableHeader = styled.button<{
  $hasLinks: boolean
  $isOpen: boolean
}>`
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: rgba(26, 35, 43, 1);
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 2px;
    width: 1px;
    height: 1px;
    border: solid black;
    border-width: 0 2px 2px 0;
    display: ${props => (props.$hasLinks ? 'inline-block' : 'none')};
    padding: 1.5px;
    transform: ${props =>
      props.$isOpen ? 'rotate(-135deg)' : 'rotate(45deg)'};
  }
`
export const ExpandableContent = styled.div<{ $isOpen: boolean }>`
  max-height: ${props => (props.$isOpen ? 'calc(100vh*2)' : '0')};
  overflow: hidden;
  transition: all 0.6s;
`
export const ExpandableLink = styled.a`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
  color: rgba(58, 66, 74, 1);
  margin: 6px 0;
  &:hover {
    color: rgb(145, 158, 165);
  }
  &:first-child {
    margin-top: 14px;
  }
`

export const ExpandableLinkText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  margin-right: 24px;
`

export const ExpandablePrice = styled.span`
  white-space: nowrap;
  text-align: right;
`

export const HeaderLink = styled.a`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  margin: 0 12px 0 0;
  color: rgba(0, 104, 239, 1);
  &:hover {
    color: rgb(0, 68, 153);
  }
`
